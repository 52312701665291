import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

interface IProps {
  value: string
  onChange: (countryCode: string) => void
}

const UserTypeSelector = (props: IProps) => {
  const [userType, setUserType] = useState(props.value)
  const { onChange } = props

  useEffect(() => {
    onChange(userType)
  }, [userType])

  const options = [
    { value: 'teacher', label: 'Teacher' },
    { value: 'parent', label: 'Parent' },
  ]

  const selectValue = useMemo(() => {
    return options.filter((o) => o.value === userType)[0]
  }, [options, userType])

  return (
    <Select
      name="userType"
      onChange={(l) => setUserType(l.value)}
      value={selectValue}
      isSearchable
      placeholder="User Type"
      options={options}
    />
  )
}


export default UserTypeSelector
