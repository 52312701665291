import React from "react"
import CreateParentAccount from "../components/CreateParentAccount";

const CreateParentAccountPage = () => {
  if (typeof window === "undefined") return null

  return <CreateParentAccount />
}

export default CreateParentAccountPage
