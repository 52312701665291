import { TextInput } from "grommet"
import { DatePicker, Button, IconButton, TrashWhite, Plus } from 'nzk-react-components'
import React, { useMemo, useRef } from "react"
import ReactSelect from 'react-select'
import styled from "styled-components"
import { colors } from "../../theme"
import Avatar from "../UI/Avatar"
import CountrySelector from './components/CountrySelector'
import UserTypeSelector from './components/UserTypeSelector'
import CreateParentAccountState from "./index.state"

const Wrapper = styled.div`
  display: flex;
  > :nth-child(2) {
    flex: 1;
    margin-left: 25px;
  }
`

const Form = styled.form`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  min-width: 280px;
  > * {
    margin-bottom: 15px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Field = styled.div`
  max-width: 240px;
  > div {
    position: relative;
    display: block;
  }
`

const Accounts = styled.div`
  min-height: 300px;
  background-color: ${colors.lighterGray};
  padding: 10px 15px;
  overflow-y: auto;
  > :first-child {
    font-size: 25px;
  }
  > :nth-child(2) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    > * {
      margin-bottom: 10px;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
`

const Account = styled.div`
  padding: 6px 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  > :first-child {
    height: 50px;
    display: flex;
    align-items: center;
    > * {
      margin-right: 10px;
    }
    > :first-child {
      margin-right: 30px;
    }
  }
  > :nth-child(2) {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  > :nth-child(3) > * {
    display: flex;
    align-items: center;
    > :first-child {
      margin-right: 10px;
    }
  }
`

const Children = styled.div`
  > div {
    margin-bottom: 10px;
    display: flex;
    > :first-child {
      flex: 1;
      margin-right: 10px;
    }
  }
`

const AddBatch = styled.div`
  > * {
    margin: 4px 8px;
  }
  input {
    width: 50px;
  }
`

const CHILD_AGES = [
  { value: 5, title: "5 years old" },
  { value: 6, title: "6 years old" },
  { value: 7, title: "7 years old" },
  { value: 8, title: "8 years old" },
  { value: 9, title: "9 years old" },
  { value: 10, title: "10 years old" },
  { value: 11, title: "11 years old" },
  { value: 12, title: "12+ years old" },
]

const STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'trialing', label: 'Trialing' },
]

const CreateParentAccount = () => {
  const {
    loading,
    accounts,
    createAccount,
    updateFormData,
    formData,
    removeChildAtIndex,
    addChild,
    updateChildAtIndex,
    addBatch,
  } = CreateParentAccountState.useContainer()
  const batchChildrenAge = useRef()
  const batchNbChildren = useRef()

  const onAddBatch = () => {
    const nbChildren = parseInt(batchNbChildren.current.value, 10)
    const childrenAges = batchChildrenAge.current.value
    if (nbChildren > 0 && childrenAges >= 0) {
      addBatch(nbChildren, childrenAges)
      batchChildrenAge.current.value = 0
      batchNbChildren.current.value = 1
    }
  }

  const statusValue = useMemo(() => {
    return STATUS_OPTIONS.filter((o) => o.value === formData.status)[0]
  }, [STATUS_OPTIONS, formData.status])

  if (loading) return <div>Loading...</div>
  return (
    <Wrapper>
      <Form>
        <Field>
          {/* eslint-disable-next-line */}
          <label>Email</label>
          <TextInput
            type="text"
            value={formData.email}
            onChange={e =>
              updateFormData({
                email:
                  e.target.value &&
                  e.target.value.toLowerCase().replace(" ", ""),
              })
            }
          />
        </Field>
        <Field>
          {/* eslint-disable-next-line */}
          <label>Name</label>
          <TextInput
            type="text"
            value={formData.name}
            onChange={e => updateFormData({ name: e.target.value })}
          />
        </Field>
        <Field>
          {/* eslint-disable-next-line */}
          <label>User Type</label>
          <UserTypeSelector value={formData.userType} onChange={userType => updateFormData({ userType })} />
        </Field>
        <Field>
          {/* eslint-disable-next-line */}
          <label>Subscription Status</label>
          <ReactSelect
            onChange={(status) => updateFormData({ status: status.value })}
            value={statusValue}
            placeholder="Status"
            options={STATUS_OPTIONS}
          />
        </Field>
        <Field>
          {/* eslint-disable-next-line */}
          <label>Country Code</label>
          <CountrySelector value={formData.countryCode} onChange={countryCode => updateFormData({ countryCode })} />
        </Field>
        <Field>
          {/* eslint-disable-next-line */}
          <label>Children ({formData.childAges.length})</label>
          <Children>
            {// eslint-disable-next-line
            formData.childAges.map((childAge, index) => (
              <div key={index}>
                <select
                  value={childAge}
                  onChange={e =>
                    updateChildAtIndex(index, parseInt(e.target.value, 10) || 5)
                  }
                >
                  {[...CHILD_AGES].map(a => (
                    <option value={a.value}>{a.title}</option>
                  ))}
                </select>
                <Button
                  size='x-small'
                  theme='red'
                  round
                  onClick={() => removeChildAtIndex(index)}
                >
                  <TrashWhite />
                </Button>
              </div>
            ))}
          </Children>
          <div>
            <IconButton theme='primary' size='x-small' onClick={addChild} icon={<Plus />}>
              Add
            </IconButton>
          </div>
          <div>Or</div>
          <AddBatch>
            Add
            <input type="number" defaultValue={1} min={1} max={100} ref={batchNbChildren} />
            children of age
            <select ref={batchChildrenAge}>
              {[...CHILD_AGES].map(a => (
                <option value={a.value}>{a.title}</option>
              ))}
            </select>
            <Button
              size='x-small'
              theme='primary'
              onClick={onAddBatch}
            >
              Add batch
            </Button>
          </AddBatch>
        </Field>
        <Field>
          {/* eslint-disable-next-line */}
          <label>Subscription End Date</label>
          <div>
            <DatePicker
              defaultValue={formData.subscriptionEndDate}
              onChange={(date) => updateFormData({ subscriptionEndDate: new Date(date) })}
            />
          </div>
        </Field>
        {!loading && <Button size='small' theme='confirm' onClick={createAccount}>Create</Button>}
      </Form>
      <Accounts>
        <div>New Accounts</div>
        <div>
          {accounts.map(account => (
            <Account key={account._id}>
              <div>
                <div>{account.email}</div>
              </div>
              <div>Children</div>
              <div>
                {account.children.map(c => (
                  <div key={c._id}>
                    <Avatar user={c} />
                    <div>{c.username}</div>
                  </div>
                ))}
              </div>
            </Account>
          ))}
        </div>
      </Accounts>
    </Wrapper>
  )
}

CreateParentAccount.propTypes = {}

CreateParentAccount.defaultProps = {}

export default props => (
  <CreateParentAccountState.Provider>
    <CreateParentAccount {...props} />
  </CreateParentAccountState.Provider>
)
