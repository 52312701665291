import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { COUNTRIES, COUNTRY_CODES } from '../../../constants/countries'

interface IProps {
  value: string
  onChange: (countryCode: string) => void
  countryCodes: string[]
}

const Flag = styled.div`
  height: 24px;
  width: 35px;
  background-image: url(${(props: { src: string }) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`

const SelectOption = styled.div`
  padding: 4px 8px;
  color: #222;
  display: flex;
  > :first-child {
    margin-right: 8px;
  }
  cursor: pointer;
`

interface ICustomCountryOptionProps {
  data: { label: string; value: string }
  innerProps: any
}

const CountryCustomOption = ({
  innerProps,
  data,
}: ICustomCountryOptionProps) => (
  <SelectOption {...innerProps}>
    <Flag
      src={`https://cdn.nightzookeeper.com/edu-assets/images/flags/${data.value}.png`}
    />
    <div>{data.label}</div>
  </SelectOption>
)

const SelectStyles = {
  control: (provided) => ({
    ...provided,
    marginLeft: '0',
    paddingLeft: '0',
  }),
  menu: (provided) => ({
    ...provided,
    paddingLeft: '0',
  }),
}

const CountrySelector = (props: IProps) => {
  const [country, setCountry] = useState(props.value)
  const { countryCodes = COUNTRY_CODES, onChange } = props

  useEffect(() => {
    onChange(country)
  }, [country])

  const options = useMemo(() => {
    return COUNTRIES.reduce((acc: { value: string; label: string }[], row) => {
      if (countryCodes.indexOf(row.value) >= 0) {
        return [...acc, row]
      }
      return acc
    }, [])
  }, [countryCodes])

  const selectValue = useMemo(() => {
    return options.filter((o) => o.value === country)[0]
  }, [options, country])

  return (
    <Select
      name="country"
      onChange={(l) => setCountry(l.value)}
      styles={SelectStyles}
      value={selectValue}
      isSearchable
      isClearable={false}
      placeholder=""
      options={options}
      components={{
        Option: CountryCustomOption,
        SingleValue: CountryCustomOption,
      }}
    />
  )
}


export default CountrySelector
