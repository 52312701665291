import { useApolloClient } from "@apollo/client"
import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { useNotificationState } from '../../components/Notifications/index.state'
import CREATE_PARENT_ACCOUNT from './graphql/createParentAccount.graphql'

const useCreateParentAccountState = () => {
  const client = useApolloClient()
  const { notifyError, notifySuccess } = useNotificationState()
  const [loading, setLoading] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [formData, setFormData] = useState({
    childAges: [],
    countryCode: 'US',
    userType: 'teacher',
    status: 'trialing',
    subscriptionEndDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) })
  const [, setError] = useState()

  useEffect(() => {
  }, [])

  const createAccount = async (input) => {
    let formErrors = false
    if (formData.childAges.length === 0) {
      formErrors = true
      notifyError({ title: 'Error', text: 'You need to add children.' })
    }
    if (formData.name === '') {
      formErrors = true
      notifyError({ title: 'Error', text: 'You need to provide a name.' })
    }
    if (!formData.userType || formData.userType === '') {
      formErrors = true
      notifyError({ title: 'Error', text: 'You need to provide a user type.' })
    }
    if (new Date(formData.subscriptionEndDate).getTime() < Date.now()) {
      formErrors = true
      notifyError({ title: 'Error', text: 'Account expiry date is in the past!' })
    }
    if (formErrors) return
    setLoading(true)
    setError(null)
    try {
      const { data } = await client.mutate({
        mutation: CREATE_PARENT_ACCOUNT,
        variables: {
          input: formData
        }
      })
      setAccounts([{ ...formData, ...data.createParentAccount }, ...accounts])
      setFormData({ childAges: [], subscriptionEndDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) })
      notifySuccess({ title: 'Success', text: 'Account has been created!' })
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const addChild = () => {
    updateFormData({ childAges: [...formData.childAges, 0] })
  }

  const addBatch = (nb, age) => {
    const newChildren = new Array(nb).fill(parseInt(age) || 5)
    updateFormData({ childAges: [...formData.childAges, ...newChildren] })
  }

  const removeChildAtIndex = (index) => {
    let newAges = [...formData.childAges]
    newAges.splice(index, 1)
    updateFormData({ childAges: newAges })
  }

  const updateChildAtIndex = (index, age) => {
    let newAges = [...formData.childAges]
    newAges[index] = parseInt(age)
    updateFormData({ childAges: newAges })
  }

  const updateFormData = (payload) => {
    setFormData({ ...formData, ...payload })
  }

  return { loading, createAccount, accounts, updateFormData, formData, addChild, removeChildAtIndex, updateChildAtIndex, addBatch }
}

const CreateParentAccountState = createContainer(useCreateParentAccountState)

export default CreateParentAccountState
